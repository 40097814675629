import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Global, css } from '@emotion/core';
import Helmet from 'react-helmet';

import favicon from '../images/favicon-mh@2x.png';

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={() => (
            <>
                <Helmet
                    title="Modern Health"
                    link={[{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }]}
                />
                <Global
                    styles={css`
                        body {
                            width: 100%;
                            height: 100%;
                            font-family: 'Avenir';
                            margin: 0;
                            font-weight: normal;
                        }
                    `}
                />
                <main>{children}</main>
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;

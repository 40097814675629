import React from 'react';

import * as S from './styles';

const ContactUsContainer = () => (
  <S.ContainerWrapper>
    <S.FooterName small>Contact Us</S.FooterName>
    <S.FooterParagraph>576 Sacramento St.</S.FooterParagraph>
    <S.FooterParagraph>San Francisco, CA 94111</S.FooterParagraph>
    <S.FooterParagraph>USA</S.FooterParagraph>
    <a href="mailto:info@joinmodernhealth.com">
      <S.FooterParagraph>careers@joinmodernhealth.com</S.FooterParagraph>
    </a>
  </S.ContainerWrapper>
);

export default ContactUsContainer;

import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Menu, Dropdown, Button } from 'antd';

import { mediaQueries, color } from '../variables';
import ContactIcons from '../shared/components/ContactIcons';
import menuIcon from '../../images/icon-menu.svg';
import RequestDemoButtonWidget from '../shared/components/RequestDemoButtonWidget';

const StyledDropdown = styled(Dropdown)`
  ${mediaQueries.xxxxl} {
    display: none;
  }
  ${mediaQueries.lg} {
    display: flex;
    margin-left: auto;
    margin-right: 0;
  }
`;

const StyledMenuIcon = styled.img`
  margin-top: 3rem;
  display: block;
  position: relative;
  width: 2rem;
  height: 1rem;
  background-repeat: no-repeat;
  ${mediaQueries.sm} {
    margin-left: 4rem;
  }
`;

const StyledMenu = styled(Menu)`
  && {
    padding: 2rem 0;
    width: 100vw;
    min-height: 650px;
    height: 100vh;
    text-align: center;
    background-color: ${color.aliceBlueLight};
    box-shadow: none;
  }
`;

const StyledDropDownLink = styled(Link)`
  && {
    font-size: 16px;
    font-weight: 500;
    color: ${color.bahamaBlue};
    margin: 0 auto;
    padding-bottom: 0.5rem;
  }
`;

const StyledButton = styled(Button)`
  && {
    width: 11rem;
    height: 3rem;
    margin-top: 2rem;
    margin-right: 0.5rem;
    border-radius: 2rem;
    color: white;
    background-image: linear-gradient(to top, ${color.summerSkyMediumDark}, ${color.turquoiseBlue});
    font-size: 16px;
    border: none;
    outline: none;
    &:hover {
      background-image: linear-gradient(to top, ${color.summerSkyDark}, ${color.turquoiseDark});
      color: ${color.white};
      border-color: ${color.mediumTurquoiseDark};
    }
    ${mediaQueries.sm} {
      width: 20rem;
      margin: 0rem auto;
    }
    ${mediaQueries.xs} {
      width: 18rem;
    }
  }
`;

const StyledItem = styled.p`
  && {
    font-size: 16px;
    font-weight: 500;
    color: ${color.bahamaBlue};
    margin: 0 auto;
    padding-bottom: 1rem;
  }
`;

const menu = (
  <StyledMenu>
    <Menu.Item>
      <StyledDropDownLink to="/businesses">For Companies</StyledDropDownLink>
    </Menu.Item>
    <Menu.Item>
      <StyledDropDownLink to="/providers">For Providers</StyledDropDownLink>
    </Menu.Item>
    <Menu.Item>
      <StyledDropDownLink to="/research">Research</StyledDropDownLink>
    </Menu.Item>
    <Menu.Item>
      <a href="https://boards.greenhouse.io/modernhealth">
        <StyledItem>Careers</StyledItem>
      </a>
    </Menu.Item>
    <Menu.Item>
      <a href="https://medium.com/modern-health">
        <StyledItem>Blog</StyledItem>
      </a>
    </Menu.Item>
    <Menu.Item>
      <ContactIcons isMobile />
    </Menu.Item>
    <Menu.Item>
      <a href="https://my.joinmodernhealth.com/login">
        <StyledButton>Log in</StyledButton>
      </a>
    </Menu.Item>
    <Menu.Item>
      <RequestDemoButtonWidget />
    </Menu.Item>
  </StyledMenu>
);

const MobileNavigation = () => (
  <StyledDropdown overlay={menu} trigger={['click']}>
    <a className="ant-dropdown-link" href="#">
      <StyledMenuIcon src={menuIcon} />
    </a>
  </StyledDropdown>
);

export default MobileNavigation;

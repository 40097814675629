import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { mediaQueries } from '../../variables';
import RequestDemoFormModal from './RequestDemoFormModal';
import { LabelButtonWrapper } from './styles';

const RequestDemoBtn = styled(Button)`
  && {
    width: 11rem;
    height: 3rem;
    margin-top: 0.75rem;
    margin-right: 1rem;
    border-radius: 2rem;
    color: white;
    background-color: #4c7de0;
    font-size: 1rem;
    border-color: #4c7de0;
    outline: none;
    box-shadow: 0 6px 12px 0 #ebeaf4;
    &:hover {
      background-color: #4470c9;
      color: white;
      border-color: #4470c9;
    }
    ${mediaQueries.sm} {
      width: 20rem;
      margin: 1rem auto;
    }
    ${mediaQueries.xs} {
      width: 18rem;
    }
  }
`;

class RequestDemoFormModalWidget extends React.Component {
  state = {
    isRequestDemoModalOpen: false,
  };

  toggleRequestDemoModal = () =>
    this.setState(state => ({ isRequestDemoModalOpen: !state.isRequestDemoModalOpen }));

  render() {
    return (
      <>
        <RequestDemoBtn onClick={this.toggleRequestDemoModal}>
          <LabelButtonWrapper>Request demo</LabelButtonWrapper>
        </RequestDemoBtn>
        <RequestDemoFormModal
          isVisible={this.state.isRequestDemoModalOpen}
          onCancel={this.toggleRequestDemoModal}
        />
      </>
    );
  }
}

export default RequestDemoFormModalWidget;

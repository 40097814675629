import React from 'react';
import { Link } from 'gatsby';

import * as S from './styles';

const PoliciesContainer = () => (
  <S.ContainerWrapper>
    <S.FooterName small>Policies and Status</S.FooterName>
    <Link to="/disclosure">
      <S.FooterParagraph>Disclosure Policy</S.FooterParagraph>
    </Link>
    <a href="https://status.joinmodernhealth.com">
      <S.FooterParagraph>System Status</S.FooterParagraph>
    </a>
    <Link to="/terms-of-service">
      <S.FooterParagraph>Terms of Service</S.FooterParagraph>
    </Link>
    <Link to="/privacy-policy">
      <S.FooterParagraph>Privacy Policy</S.FooterParagraph>
    </Link>
  </S.ContainerWrapper>
);

export default PoliciesContainer;

import styled from '@emotion/styled';
import { Modal, Input, Row, Col, Button, Select } from 'antd';

import { mediaQueries, color } from '../../variables';

export const SectionWelcomeHeading = styled.h2(({ small }) => ({
  color: color.bahamaBlue,
  margin: '0 0 1rem',
  lineHeight: '1.25',
  fontSize: small ? '40px' : '46px',
  fontWeight: 'bold',
  [mediaQueries.xxl]: {
    fontSize: '35px',
  },
  [mediaQueries.lg]: {
    fontSize: '31px',
    textAlign: 'center',
  },
  [mediaQueries.md]: {
    fontSize: '24px',
  },
  [mediaQueries.sm]: {
    color: color.bahamaBlue,
    fontSize: '24px',
  },
}));

export const SectionHeading = styled.h2(({ small }) => ({
  color: small ? color.echoBlue : color.bahamaBlue,
  margin: '0 0 1rem',
  fontSize: small ? '18px' : '40px',
  fontWeight: small ? 300 : 600,
  paddingBottom: small ? 0 : '1rem',
  [mediaQueries.lg]: {
    fontSize: small ? '18px' : '38px',
  },
  [mediaQueries.md]: {
    fontSize: small ? '18px' : '28px',
  },
  [mediaQueries.sm]: {
    fontSize: small ? '18px' : '28px',
    paddingBottom: 0,
    fontWeight: 600,
  },
}));

export const SectionDescription = styled.p(({ small, semiTranspatentOpacity }) => ({
  fontSize: '18px',
  fontWeight: 'normal',
  color: color.bermudaGrey,
  lineHeight: 1.8,
  opacity: semiTranspatentOpacity && '0.84',
  maxWidth: small && '27rem',
  [mediaQueries.md]: {
    margin: small && '0 auto',
    textAlign: 'center',
    fontSize: '16px',
  },
  [mediaQueries.sm]: {
    fontSize: '15px',
  },
}));

export const SectionSwipeHeading = styled.h2({
  color: color.white,
  margin: '0 0 1rem',
  fontSize: '28px',
  fontWeight: 200,
  paddingBottom: '1rem',
  lineHeight: 1.71,
  [mediaQueries.md]: {
    fontSize: '20px',
  },
});

export const ContentRow = styled(Row)(({ whitepaper }) => ({
  width: '100%',
  margin: '0 auto',
  backgroundColor: whitepaper ? color.cornflowerBlue : color.white,
  borderRadius: '1rem',
}));

export const ContentColumn = styled(Col)(({ withicon, whitepaper }) => ({
  textAlign: 'left',
  padding: withicon ? '9rem 3rem 4rem' : '3rem 3rem',
  borderRadius: withicon ? '1rem 0 0 1rem' : '1rem',
  backgroundColor: withicon ? color.aliceBlue : color.white,
  [mediaQueries.xl]: {
    padding: withicon ? '5rem 3rem 8rem' : '3rem 2rem',
  },
  [mediaQueries.lg]: {
    maxWidth: whitepaper && '28rem',
    margin: whitepaper && '0 auto',
    display: withicon ? 'none' : 'block',
  },
  [mediaQueries.sm]: {
    padding: '1.5rem 1rem',
  },
}));

export const ContentWrapper = styled(Col)`
  background-color: ${color.aliceBlue};
  border-radius: 1rem;
  padding: 2rem 4rem;
  text-align: center;
`;

export const StyledModal = styled(Modal)(({ whitepaper }) => ({
  '&&': {
    width: '100% !important',
    padding: '0',
    margin: '0 auto',
    borderRadius: '1rem',
    justifyContent: 'center',
    display: 'flex',
    [mediaQueries.lg]: {
      display: 'block',
      maxWidth: '46rem',
    },
    [mediaQueries.md]: {
      padding: 0,
    },
    [mediaQueries.sm]: {
      display: 'block',
      padding: '0 1rem',
    },
    [mediaQueries.xs]: {
      padding: '0 0.5rem',
    },
  },
  '& .ant-modal-body': {
    padding: '0',
    borderRadius: '1rem',
  },
  '& .ant-modal-content': {
    maxWidth: '78rem',
    padding: '0',
    borderRadius: '1rem',
    backgroundColor: whitepaper && color.cornflowerBlue,
    [mediaQueries.lg]: {
      maxWidth: '28rem',
      margin: '0 auto',
    },
  },
  '.ant-modal-close': {
    color: whitepaper ? color.white : color.manatee,
    [mediaQueries.lg]: {
      color: color.manatee,
    },
  },
}));

export const ModalTitle = styled.h3`
  font-size: 32px;
  color: ${color.bahamaBlue};
  ${mediaQueries.sm} {
    font-size: 28px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.span`
  font-size: 16px;
  color: ${color.manatee};
`;

export const StyledInput = styled(Input)`
  background-color: ${color.aliceBlue};
  margin-bottom: 1rem;
  max-width: 24rem;
  height: 3rem;
  border: none;
`;

export const StyledTextArea = styled(Input.TextArea)`
  background-color: ${color.aliceBlue};
  margin-bottom: 1rem;
  max-width: 24rem;
  border: none;
`;

export const ButtonWrapper = styled.div`
  ${mediaQueries.lg} {
    display: flex;
    justify-content: center;
  }
`;

export const ModalButton = styled.button(({ small }) => ({
  width: '100%',
  maxWidth: small ? '19rem' : '24rem',
  height: '3rem',
  backgroundImage: `linear-gradient(to top, ${color.summerSkyMediumDark}, ${color.turquoiseBlue})`,
  color: color.white,
  borderRadius: '2rem',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundImage: `linear-gradient(to top, ${color.summerSkyDark}, ${color.turquoiseDark})`,
    color: color.white,
    borderColor: color.mediumTurquoiseDark,
  },
  '&:focus': {
    outline: 'none',
  },
}));

export const ModalIcon = styled.i(({ small, iconUrl }) => ({
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: small ? 'auto' : '30rem',
  height: small ? '19rem' : '25rem',
  margin: '0 0 3rem',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  [mediaQueries.xl]: {
    width: small ? 'auto' : '23rem',
  },
}));

export const ModalDescription = styled.p(({ center, small }) => ({
  maxWidth: center ? 'none' : '35rem',
  fontSize: small ? ' 15px' : '20px',
  color: color.bermudaGrey,
  lineHeight: small ? 2 : 1.5,
  [mediaQueries.sm]: {
    fontSize: '16px',
  },
}));

export const UserEmail = styled.span`
  color: ${color.royalBlue};
`;

export const ButtonsWrapper = styled.div`
  ${mediaQueries.lg} {
    display: flex;
    justify-content: center;
  }
  ${mediaQueries.sm} {
    display: flex;
    flex-direction: column;
  }
`;

export const RequestDemoButton = styled(Button)`
  && {
    width: 14rem;
    height: 3rem;
    margin-top: 2rem;
    margin-right: 0.5rem;
    border-radius: 2rem;
    color: white;
    background-image: linear-gradient(to top, ${color.summerSky}, ${color.turquoise});
    border: none;
    font-size: 1rem;
    outline: none;
    &:hover {
      background-image: linear-gradient(
        to top,
        ${color.pacificBlueLight},
        ${color.mediumTurquoise}
      );
    }
    ${mediaQueries.sm} {
      width: 21rem;
      margin: 2rem auto;
    }
    ${mediaQueries.xs} {
      width: 18rem;
    }
  }
`;

export const CardHeading = styled.h2`
  color: ${color.bahamaBlue};
  margin: 0 0 1rem;
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 1rem;
  ${mediaQueries.lg} {
    margin: 0 auto;
    font-size: 38px;
  }
  ${mediaQueries.md} {
    font-size: 28px;
  }
  ${mediaQueries.sm} {
    font-size: 22px;
    padding-bottom: 0;
    font-weight: 600;
  }
`;

export const StyledSelect = styled(Select)`
  && .ant-select-selection {
    background-color: ${color.aliceBlue};
    margin-bottom: 1rem;
    max-width: 24rem;
    height: 3rem;
    border: none;
    height: fit-content;
    && .ant-select-dropdown-menu {
      position: absolute;
      top: 5rem !important;
    }
  }
  && .ant-select-selection-selected-value {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    white-space: inherit;
  }
  && .ant-select-selection__rendered {
    display: flex;
  }
`;

export const LabelButtonWrapper = styled.span`
  margin-top: 0.2rem;
`;

import React from 'react';
import { Link } from 'gatsby';

import * as S from './styles';
import ContactIcons from '../../shared/components/ContactIcons';

const FooterHeaderContainer = () => (
  <S.ContainerWrapper>
    <S.FooterName>Modern Health</S.FooterName>
    <S.FooterParagraph>
      A platform for innovative companies that value the emotional well-being of their workforce.
    </S.FooterParagraph>
    <ContactIcons />
  </S.ContainerWrapper>
);

export default FooterHeaderContainer;

const mediaQ = size => `@media only screen and (max-width: ${size}px)`;

export const layout = {
  headerHeight: '4rem',
  modalWidth: {
    wide: 860,
  },
};

export const mediaQueries = {
  xs: mediaQ(380),
  sm: mediaQ(576),
  md: mediaQ(768),
  lg: mediaQ(992),
  xl: mediaQ(1200),
  xxl: mediaQ(1440),
  xxxl: mediaQ(1600),
  xxxxl: mediaQ(4000),
};

export const color = {
  aliceBlue: '#f6f9ff',
  aliceBlueLight: '#fafbfd',
  white: '#ffffff',
  royalBlue: '#4c7de0',
  royalBlueDark: '#4470c9',
  shamrock: '#27c59b',
  jungleGreen: '#23b18b',
  mediumTurquoise: '#53d6cf',
  mediumTurquoiseDark: '#4ac0ba',
  bahamaBlue: '#1c4b81',
  midnight: '#1a2839',
  manatee: '#969baa',
  solitude: '#e5e9ef',
  raven: '#687284',
  echoBlue: '#afb5bf',
  bermudaGrey: '#6e8997',
  black: '#000000',
  mariner: '#385fae',
  linkWater: '#c6cad7',
  echoBlue: '#a7adbf',
  cornflowerBlue: '#6a95f1',
  pacificBlue: '#008794',
  baliHali: '#8197b1',
  irisBlue: '#00b8c2',
  resolutionBlue: '#384974',
  darkCyan: '#008491',
  turquoise: '#47f4eb',
  turquoiseDark: '#20D9D0',
  solitudeLight: '#e9f0fd',
  summerSky: '#4cb7e0',
  summerSkyDark: '#23AADD',
  pacificBlueLight: '#0fa0d8',
  lightBlue: '#c4d4dc',
  snow: '#f9f9f9',
  lavender: '#ebeaf4',
  summerSkyMediumDark: '#3fc6df',
  turquoiseBlue: '#69d8ec',
  marinerDark: '#4269B8',
  cornflowerBlue: '#0277a4',
  perfume: '#b6aff8',
  darkTurquoise: '#1dc6d8',
};

import React from 'react';
import styled from '@emotion/styled';
import { Row } from 'antd';

import footerDesktopBackground from '../../images/bg-footer.svg';

import FooterSection from './FooterSection';
import { mediaQueries, color } from '../variables';

const SectionWrapper = styled(Row)(({ homepage }) => ({
  backgroundSize: 'cover',
  backgroundImage: homepage
    ? `linear-gradient(to top, ${color.resolutionBlue}, ${color.marinerDark})`
    : `url(${footerDesktopBackground})`,
  [mediaQueries.md]: {
    backgroundImage: `linear-gradient(to top, ${color.resolutionBlue}, ${color.royalBlueDark})`,
  },
}));

const FooterWrapper = ({ children, homepage, noLine, extraPadding }) => (
  <SectionWrapper homepage={homepage}>
    {children}
    <FooterSection noLine={noLine} extraPadding={extraPadding} />
  </SectionWrapper>
);

export default FooterWrapper;

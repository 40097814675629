import React from 'react';

import modalIcon from '../../../images/illu-placeholder.svg';
import tadaIcon from '../../../images/tada-20.svg';
import { handleRequestDemoFormSubmit } from '../../../services/RequestFormService';
import * as S from './styles';

const initialState = {
  firstName: '',
  lastName: '',
  workEmail: '',
  workPhone: '',
  companyName: '',
  companySize: '',
  title: '',
  message: '',
  showThanks: false,
};

class RequestDemoFormModal extends React.Component {
  state = initialState;

  handleInputChange = event => this.setState({ [event.target.name]: event.target.value });

  toggleThanksModal = () => this.setState({ showThanks: !this.state.showThanks });

  handleSubmit = event => {
    event.preventDefault();
    handleRequestDemoFormSubmit(this.state)
      .then(this.toggleThanksModal)
      .catch(alert);
  };

  handleModalClose = () => {
    this.props.onCancel();
    this.setState(initialState);
  };

  render() {
    const { onCancel, isVisible } = this.props;

    return (
      <S.StyledModal footer={false} closable={true} visible={isVisible} onCancel={onCancel}>
        {this.state.showThanks ? (
          <S.ContentRow>
            <S.ContentWrapper>
              <S.ModalIcon small iconUrl={tadaIcon} />
              <S.ModalTitle>Thank you!</S.ModalTitle>
              <S.ModalDescription center>
                Member of our team will reach out to you soon.
              </S.ModalDescription>
              <S.ModalDescription center>
                We will send an email to:
                <S.UserEmail> {this.state.workEmail}</S.UserEmail>
              </S.ModalDescription>
              <S.ModalButton small onClick={this.handleModalClose}>
                Back to Modern Health
              </S.ModalButton>
            </S.ContentWrapper>
          </S.ContentRow>
        ) : (
          <S.ContentRow>
            <S.ContentColumn withicon={1} lg={14}>
              <S.ModalIcon iconUrl={modalIcon} />
              <S.ModalTitle>We'd love to hear from you!</S.ModalTitle>
              <S.ModalDescription>
                Fill out this form, and we'll have a member of our team reach out to you right away.
              </S.ModalDescription>
            </S.ContentColumn>
            <S.ContentColumn md={24} lg={10}>
              <S.ModalTitle>Request demo</S.ModalTitle>
              <form onSubmit={this.handleSubmit}>
                <S.InputWrapper>
                  <S.InputLabel>First Name*</S.InputLabel>
                  <S.StyledInput
                    type="text"
                    name="firstName"
                    placeholder="Jim"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Last Name*</S.InputLabel>
                  <S.StyledInput
                    type="text"
                    name="lastName"
                    placeholder="Rohn"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Work Email*</S.InputLabel>
                  <S.StyledInput
                    type="email"
                    name="workEmail"
                    placeholder="jim.rohn@company.com"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Work Phone*</S.InputLabel>
                  <S.StyledInput
                    type="number"
                    name="workPhone"
                    placeholder="555-765-4321"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Company Name*</S.InputLabel>
                  <S.StyledInput
                    type="text"
                    name="companyName"
                    placeholder="Company Ltd"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Company Size*</S.InputLabel>
                  <S.StyledInput
                    type="number"
                    name="companySize"
                    placeholder="200"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Title*</S.InputLabel>
                  <S.StyledInput
                    type="text"
                    name="title"
                    placeholder="Head of Human Resouces"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Message*</S.InputLabel>
                  <S.StyledTextArea
                    rows={4}
                    type="text"
                    name="message"
                    placeholder="How can we help?"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.ButtonWrapper>
                  <S.ModalButton>Submit</S.ModalButton>
                </S.ButtonWrapper>
              </form>
            </S.ContentColumn>
          </S.ContentRow>
        )}
      </S.StyledModal>
    );
  }
}

export default RequestDemoFormModal;

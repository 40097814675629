import React from 'react';
import styled from '@emotion/styled';

import facebookIcon from '../../../images/icon-fb.svg';
import linkedInIcon from '../../../images/icon-linkedin.svg';
import instagramIcon from '../../../images/icon-instagram.svg';

const contacts = [
  { icon: facebookIcon, link: 'https://www.facebook.com/modernhealthco/' },
  { icon: linkedInIcon, link: 'https://www.linkedin.com/company/modern-health' },
  { icon: instagramIcon, link: 'https://www.instagram.com/modernhealthco/' },
];

const IconsWrapper = styled.div(({ isMobile }) => ({
  display: 'flex',
  justifyContent: isMobile && 'center',
}));

const SectionIcon = styled.i(({ iconUrl }) => ({
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: '3rem',
  height: '3rem',
  backgroundRepeat: 'no-repeat',
  margin: '1rem 0.5rem 0 0',
}));

const ContactIcons = ({ isMobile }) => (
  <IconsWrapper isMobile={isMobile}>
    {contacts.map(contact => (
      <a key={contact.icon} href={contact.link}>
        <SectionIcon iconUrl={contact.icon} />
      </a>
    ))}
  </IconsWrapper>
);

export default ContactIcons;

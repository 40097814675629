import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Row, Col } from 'antd';

import mhLogo from '../../images/mh-logo.svg';
import { mediaQueries } from '../variables';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';

const NavigationWrapper = styled(Row)`
  width: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 2;
  padding-bottom: 1.5rem;
  display: flex;
  background-color: transparent;
  justify-content: space-around;
`;

const StyledLogo = styled.img`
  padding-top: 2.5rem;
  padding-left: 4rem;
  ${mediaQueries.sm} {
    padding-left: 1rem;
  }
`;

const LogoWrapper = styled.div`
  ${mediaQueries.lg} {
    display: flex;
    width: 100%;
  }
`;

const DesktopNavigationContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  ${mediaQueries.xxl} {
    padding-right: 1rem;
  }
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const DesktopNavigationWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  padding-bottom: 0.5rem;
  ${mediaQueries.xl} {
    margin: 0;
  }
`;

const Navigation = () => (
  <NavigationWrapper>
    <Col md={24} lg={4} xl={4}>
      <LogoWrapper>
        <Link to="/">
          <StyledLogo src={mhLogo} alt="Modern Health" />
        </Link>
        <MobileNavigation />
      </LogoWrapper>
    </Col>
    <DesktopNavigationContainer lg={20} xl={20}>
      <DesktopNavigationWrapper>
        <DesktopNavigation />
      </DesktopNavigationWrapper>
    </DesktopNavigationContainer>
  </NavigationWrapper>
);

export default Navigation;

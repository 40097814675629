import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { Link } from 'gatsby';

import { mediaQueries, color } from '../variables';
import CustomersContainer from './components/CustomersContainer';
import AboutUsContainer from './components/AboutUsContainer';
import ContactUsContainer from './components/ContactUsContainer';
import PoliciesContainer from './components/PoliciesContainer';
import FooterHeaderContainer from './components/FooterHeaderContainer';

const SectionWrapper = styled(Col)(({ extraPadding }) => ({
  padding: extraPadding ? '7rem 1rem 0' : '3rem 1rem 0',
  width: '100%',
  backgroundColor: 'transparent',
  backgroundSize: 'cover',
  [mediaQueries.xl]: {
    padding: '2rem 1rem 0',
  },
  [mediaQueries.sm]: {
    padding: '1rem 1rem 0',
  },
}));

const ContentRow = styled(Row)`
  width: 100%;
  padding: 2rem 7rem 2rem 10em;
  display: block;
  text-align: center;
  ${mediaQueries.xxl} {
    padding: 2rem 5rem;
  }
  ${mediaQueries.xl} {
    padding: 0;
  }
  ${mediaQueries.sm} {
    display: block;
  }
`;

const CopyrightParagraph = styled.p(({ hideOnMobile }) => ({
  color: color.manatee,
  opacity: '0.5',
  fontSize: '14px',
  [mediaQueries.sm]: {
    display: hideOnMobile ? 'none' : 'block',
  },
}));

const StyledLine = styled.hr(({ noLine }) => ({
  width: '89%',
  height: '1px',
  border: 'none',
  backgroundColor: color.perfume,
  textAlign: 'left',
  [mediaQueries.xl]: {
    width: '100%',
  },
  display: noLine && 'none',
}));

const FooterSection = ({ noLine, extraPadding }) => (
  <SectionWrapper extraPadding={extraPadding}>
    <StyledLine noLine={noLine} />
    <ContentRow>
      <Col sm={24} md={6} lg={6} xl={6}>
        <FooterHeaderContainer />
      </Col>
      <Col sm={24} md={2} lg={2} xl={2} />
      <Col sm={24} md={4} lg={4} xl={4}>
        <CustomersContainer />
      </Col>
      <Col sm={24} md={4} lg={4} xl={4}>
        <AboutUsContainer />
      </Col>
      <Col sm={24} md={4} lg={4} xl={4}>
        <PoliciesContainer />
      </Col>
      <Col sm={24} md={4} lg={4} xl={4}>
        <ContactUsContainer />
      </Col>
    </ContentRow>
    <ContentRow css={{ padding: '0 4rem', textAlign: 'left' }}>
      <Col sm={24} md={6} lg={8}>
        <CopyrightParagraph>©2019 Modern Healthcare, Inc. All rights reserved</CopyrightParagraph>
      </Col>
    </ContentRow>
  </SectionWrapper>
);

export default FooterSection;

import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Button, Dropdown, Icon, Menu } from 'antd';

import { mediaQueries, color } from '../variables';
import dropdownIcon from '../../images/Polygon.svg';
import { LabelButtonWrapper } from '../shared/components/styles';

const NavigationList = styled.ul`
  padding: 1rem 0 0;
  list-style: none;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 auto;
  ${mediaQueries.lg} {
    display: none;
  }
`;

const NavigationItem = styled.li`
  padding-top: 2rem;
  margin: 0;
  margin-left: 3rem;
  ${mediaQueries.xl} {
    margin-left: 2rem;
  }
  ${mediaQueries.lg} {
    margin-left: 0.5rem;
  }
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  text-decoration: none;
  color: ${color.manatee};
  &:hover {
    color: ${color.bahamaBlue};
  }
`;

const StyledA = styled.a`
  font-size: 16px;
  text-decoration: none;
  color: ${color.manatee};
  &:hover {
    color: ${color.bahamaBlue};
  }
`;

const StyledButton = styled(Button)`
  && {
    width: 10rem;
    height: 2.5rem;
    margin-top: 1.3rem;
    margin-left: 3.5rem;
    border-radius: 2rem;
    color: white;
    background-image: linear-gradient(to top, ${color.summerSkyMediumDark}, ${color.turquoiseBlue});
    font-size: 16px;
    border: none;
    outline: none;
    &:hover {
      background-image: linear-gradient(to top, ${color.summerSkyDark}, ${color.turquoiseDark});
      color: ${color.white};
      border-color: ${color.mediumTurquoiseDark};
    }
    ${mediaQueries.xl} {
      width: 10rem;
      margin-left: 2.5rem;
    }
  }
`;

const StyledMenu = styled(Menu)`
  && {
    border-radius: 10px;
    border: solid 1.5px ${color.lightBlue};
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  && {
    margin: 0.5rem;
    border-radius: 10px;
  }
`;

const StyledDropdownLink = styled.a`
  font-size: 16px;
  color: ${color.manatee};
  border-radius: 10px;
  border: none;
  &:hover {
    background-color: ${color.snow};
  }
`;

const StyledDropdownIcon = styled.img`
  display: block;
  position: relative;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  margin-left: 1rem;
  top: 0.25rem;
`;

const menu = (
  <StyledMenu>
    <StyledMenuItem>
      <StyledLink
        css={{
          '&:hover': {
            backgroundColor: color.snow,
          },
        }}
        to="/research"
      >
        Research
      </StyledLink>
    </StyledMenuItem>
    <StyledMenuItem>
      <StyledDropdownLink href="https://medium.com/modern-health">Blog</StyledDropdownLink>
    </StyledMenuItem>
  </StyledMenu>
);

const DesktopNavigation = () => (
  <NavigationList>
    <NavigationItem>
      <StyledLink to="/businesses">For Companies</StyledLink>
    </NavigationItem>
    <NavigationItem>
      <StyledLink to="/providers">For Providers</StyledLink>
    </NavigationItem>
    <NavigationItem>
      <StyledA href="https://boards.greenhouse.io/modernhealth">Careers</StyledA>
    </NavigationItem>
    <a href="https://my.joinmodernhealth.com/login">
      <StyledButton>
        <LabelButtonWrapper>Log in</LabelButtonWrapper>
      </StyledButton>
    </a>
  </NavigationList>
);

export default DesktopNavigation;

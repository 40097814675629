import React from 'react';
import { Link } from 'gatsby';

import * as S from './styles';

const AboutUsContainer = () => (
  <S.ContainerWrapper>
    <S.FooterName small>About Us</S.FooterName>
    <Link to="/about">
      <S.FooterParagraph>Modern Health</S.FooterParagraph>
    </Link>
    <a href="https://boards.greenhouse.io/modernhealth">
      <S.FooterParagraph>Careers</S.FooterParagraph>
    </a>
    <a href="https://medium.com/modern-health">
      <S.FooterParagraph>Blog</S.FooterParagraph>
    </a>
  </S.ContainerWrapper>
);

export default AboutUsContainer;

import styled from '@emotion/styled';

import { color } from '../../variables';

export const ContainerWrapper = styled.div`
  text-align: left;
  margin: 2rem 0;
`;

export const FooterName = styled.h3(({ small }) => ({
  color: color.white,
  fontSize: small ? '18px' : '28px',
  fontWeight: 500,
}));

export const FooterParagraph = styled.p`
  color: ${color.aliceBlue};
  font-size: 14px;
  opacity: 0.7;
  line-height: 1.75;
  font-weight: 200;
`;

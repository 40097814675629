export const config = {
  requestDemoApiUrl: `${process.env.EMAIL_API_ADDRESS  }emails/request-demo`,
  requestBenefitsApiUrl: `${process.env.EMAIL_API_ADDRESS  }emails/request-benefits`,
  requestWhitepaperApiUrl: `${process.env.EMAIL_API_ADDRESS  }emails/request-whitepaper`,
  inboundProviderContactApiUrl: `${process.env.EMAIL_API_ADDRESS  }emails/inbound-provider`,

  appStoreUrl: 'https://apps.apple.com/app/modern-health/id1445843859#?platform=ipad',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.modernhealth.modernhealth',
  providerFormUrl:
    'https://docs.google.com/forms/d/e/1FAIpQLSenM20y9DXZkXcd3K0prObCE8cbr2LeG3yD-KL8EMp4mZfaDA/viewform',
};

import _ from 'lodash';

import { config } from '../config';

const createZendeskRequest = (url, message) => {
  const body = JSON.stringify({
    message,
  });

  return fetch(url, {
    body,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  });
};

const beautifyRequestDemoFormData = userInfo => ({
  'first_name': userInfo.firstName,
  'last_name': userInfo.lastName,
  'email': userInfo.workEmail,
  'phone': userInfo.workPhone,
  'company_name': userInfo.companyName,
  'company_size': userInfo.companySize,
  'title': userInfo.title,
  'message': userInfo.message,
});

export const handleRequestDemoFormSubmit = userInfo => {
  const data = beautifyRequestDemoFormData(userInfo);
  return createZendeskRequest(config.requestDemoApiUrl, data);
};

const beautifyRequestTheseBenefitsFormData = userInfo => ({
  'hr_contact_first_name': userInfo.firstName,
  'hr_contact_last_name': userInfo.lastName,
  'hr_contact_email': userInfo.email,
  'hr_contact_phone': userInfo.phone,
  'company_name': userInfo.companyName,
  'company_size': userInfo.companySize,
});

export const handleRequestTheseBenefitsFormSubmit = userInfo => {
  const data = beautifyRequestTheseBenefitsFormData(userInfo);
  return createZendeskRequest(config.requestBenefitsApiUrl, data);
};

const beautifyRequestWhitepaperFormData = userInfo => ({
  'first_name': userInfo.firstName,
  'last_name': userInfo.lastName,
  'email': userInfo.email,
  'research_nature': userInfo.researchNature,
  'organization_name': userInfo.organizationName,
});

export const handleRequestWhitepaperFormSubmit = userInfo => {
  const data = beautifyRequestWhitepaperFormData(userInfo);
  return createZendeskRequest(config.requestWhitepaperApiUrl, data);
};

const beautifyInboundProviderContactFormData = userInfo => ({
  'first_name': userInfo.firstName,
  'last_name': userInfo.lastName,
  'email': userInfo.email,
  'provider_type': userInfo.providerType,
  'country': userInfo.country,
  'city': userInfo.city,
  'postal_code': userInfo.postalCode,
});

export const handleInboundProviderContactFormSubmit = userInfo => {
  const data = beautifyInboundProviderContactFormData(userInfo);
  return createZendeskRequest(config.inboundProviderContactApiUrl, data);
};

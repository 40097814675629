import React from 'react';
import { Link } from 'gatsby';

import * as S from './styles';

const CustomersContainer = () => (
  <S.ContainerWrapper>
    <S.FooterName small>Modern Health</S.FooterName>
    <Link to="/businesses">
      <S.FooterParagraph> For Companies</S.FooterParagraph>
    </Link>
    <Link to="/employees">
      <S.FooterParagraph>For Employees</S.FooterParagraph>
    </Link>
    <Link to="/providers">
      <S.FooterParagraph>For Providers</S.FooterParagraph>
    </Link>
  </S.ContainerWrapper>
);

export default CustomersContainer;
